<template>
    <div class="mx-3  py-5">
    <v-col md="12"></v-col>
   
      <v-row>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" outlined
            dense >
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" outlined
            dense>
          </v-text-field>
        </v-col>  
        <v-col md="3">
          <v-text-field
            v-model="filters.serial"
            :label="$t('Receiving/Delivery Number')"
            outlined
            dense
          ></v-text-field>
        </v-col>
  
        <v-col class="py-0" md="3">
          <AutoCompleteField
            :label="$t('Warehouseman')"
            outlined
            
            v-model="filters.user_id"
            endPoint="/user/auto-complete"
          />
        </v-col>
        <v-col class="py-0" md="3">
          <AutoCompleteField
            :label="$t('Inventory Name')"
            outlined
            dense
            v-model="filters.inventory_id"
            endPoint="/inventories/auto-complete"
          />
        </v-col>
        
        <v-col md="9">
          <v-text-field
            v-model="filters.notes"
            :label="$t('notes')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
            <v-btn color="blue" dark block  @click="exportToExcel(filters)"
              >{{ $t('hr.time.export') }}
              </v-btn>
          </v-col>
          
      </v-row>
  
  
  
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 py-5"
        hide-default-footer
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
         
          <v-btn @click="invoiceTypeToHandler(item)" color="blue" icon>
            <v-icon class="ml-1">mdi-pen</v-icon></v-btn
          >
          <DeleteDelivery path="inventories/delivery-note" :id="item.id" @record-deleted="getAll" />
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { saveAs } from 'file-saver';
  import AutoCompleteField from "../invoices/AutoCompleteField.vue";
  import DeleteDelivery from "../../components/core/delete.vue";
  import i18n from "../../../../i18n";
  export default {
    components: {   AutoCompleteField, DeleteDelivery  },
    data: () => ({
     
      types: [
        {
          label: i18n.t('purchase'),
          value: "PURCHASES",
        },
        {
          label: i18n.t('sales'),
          value: "SALES",
        },
        {
          label: i18n.t('purchase refunds'),
          value: "PURCHASE_REFUNDS",
        },
        {
          label: i18n.t('sales refunds'),
          value: "SALES_REFUNDS",
        },
        {
          label: i18n.t('transportation'),
          value: "TRANSPORTATION",
        },
      ],
      tableItems: [],
      invoiceTypes: [],
      loading: false,
      page: 1,
      total: 1,
      headers: [
        { text: i18n.t('id'), value: "id" },
        { text: i18n.t('Receiving/Delivery Number'), value: "serial" },
        { text: i18n.t('Order Number'), value: "order_serial" },
        { text: i18n.t('date'), value: "date" },
        { text: i18n.t('Inventory Name'), value: "inventory.name" },
        { text: i18n.t('Warehouseman'), value: "user.name" },
        { text: i18n.t('Amount'), value: "quantity" },       
        { text: i18n.t('notes'), value: "notes" },
        { text: "", value: "actions" },
      ],
      items: [],
      filters: {
        serial: null,
        from: null,
        to: null,
        user_id: null,
        inventory_id: null,
        notes: null,
      },
    }),
    methods: {
      clearFilter() {
        this.filters.serial = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.user_id = undefined;
        this.filters.inventory_id = undefined;
        this.filters.notes = undefined;

        this.page = 1;
        this.getAll();
      },
      async exportToExcel(queryParams){
           
           try {
      
       const excel = await axios.get(`/inventories/invoices/export`, {
         params: {
           ...queryParams,
           
         },
         responseType: 'blob'
       });
       saveAs(excel.data)
       
  
     } catch (err) {
       console.log('err', err);
     } finally {
       
     }
       
       },
      invoiceTypeToHandler(item) {
       
        this.$router.push(
            `/invoiceWarehouse/${item.id}`
          );

      },
      async getAll(page) {
        try {
          if (!page) this.page = 1;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          const res = await axios.get("/inventories/delivery-note/index", {
            params: {
              size: 10,
              page: this.page,
              ...filterTemp,
            },
          });
          this.tableItems = res.data.data.map((el) => {
            const typeTemp = this.types.find(
              (typ) => el?.invoice_type?.type == typ.value
            );
            return {
              ...el,
              invoiceTypeName: el?.invoice_type?.name,
              clientAccountName: el?.client_account?.name,
              type: typeTemp ? typeTemp.label : " ",
              invoiceStatus: el.is_locked ? "Locked" : "UnLocked",
            };
          });
          this.page = res.data.meta.current_page;
          this.total = res.data.meta.total;
        } finally {
          this.loading = false;
        }
      },
 
      itemClicked(v) {
     
          this.$router.push(`/new-invoice?invoice_id=${v.id}`);
        
      },
  
    },
    created() {
      
    
    },
  };
  </script>
  
  <style></style>
  